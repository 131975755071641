import {useState} from 'react'
import logger from '../utils/logger'
import {Alert, Button, Col, Form, Image, InputGroup, Row} from 'react-bootstrap'
import underConstruction from '../assets/images/under-construction.webp'
import {getUnits, statToText} from '../utils/stats'
import {raceAssets, classAssets, alignmentAssets} from '../utils/assets'

const CreateNewCharacter = ({socket, setCharacters, onGoBack}) => {
  const [name, setName] = useState('')
  const [alignment, setAlignment] = useState(1)
  const [race, setRace] = useState('human')
  const [gameClass, setGameClass] = useState('warrior')

  const [validated, setValidated] = useState(false)
  const [error, setError] = useState('')

  const handleSubmit = (event) => {
    event.preventDefault()
    event.stopPropagation()
    setValidated(true)

    logger.log('CreateNewCharacter :: EMIT :: createCharacter')

    socket.emit('createCharacter', {name, race, gameClass, alignment}, (response) => {
      if (response.success) {
        logger.log('CreateNewCharacter :: EMIT RESPONSE :: createCharacter :: ' + JSON.stringify(response.character))
        setCharacters(prev => [...prev, response.character])
      } else {
        setError(response.message || 'Error')
      }
    })
  }

  return (
    <>
      <Row className="justify-content-center mb-4">
        <Col md={4} className="mt-3 mt-lg-4">
          <Button variant="secondary" type="button" onClick={onGoBack}>Back</Button>
        </Col>
      </Row>

      <Row className="text-center mb-4">
        <Col>
          <h2>Create New Character</h2>
        </Col>
      </Row>

      {error &&
        <Row className="justify-content-center mb-4"><Col md={4}><Alert variant="danger">{error}</Alert></Col></Row>}

      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Row className="justify-content-center">
          <Form.Group as={Col} md={4} className="mb-4" controlId="formBasicUsername">
            <Form.Label className="m-0">Character Name*</Form.Label>
            <InputGroup>
              <Form.Control
                type="text"
                placeholder="Name"
                minLength={3}
                maxLength={15}
                value={name}
                pattern="[a-zA-Z ]*"
                onChange={(e) => setName(e.target.value)}
                required
              />
            </InputGroup>
          </Form.Group>
        </Row>

        <Row className="text-center">
          <Col>
            <h4>Alignment</h4>
          </Col>
        </Row>
        <Row>
          <Col className="text-center">
            {alignments.map((al, i) => (
                <div
                  key={i}
                  className={(i === 1 ? 'selectable ' : 'under-construction ') + 'dw-card position-relative mx-2 mb-4 p-2' + (i === alignment ? ' active ' : '')}
                  onClick={() => {
                    if (i !== 1) { // todo - tmp disabled all except evil
                      return
                    }
                    setAlignment(i)
                    if (!gameClasses[gameClass].alignments.includes(i)) {
                      setGameClass('warrior')
                    }
                  }}
                >
                  <Image style={styles.selectable} src={alignmentAssets[`align${i}`]} alt={al} className="mr-3 mb-2" thumbnail/>
                  <div><strong className="mb-1">{al}</strong></div>
                  {(i !== 1) && <Image style={styles.underConstruction} src={underConstruction} alt=""/>}
                </div>
            ))}
          </Col>
        </Row>


        <Row className="text-center">
          <Col>
            <h4>Race</h4>
          </Col>
        </Row>
        <Row>
          <Col className="text-center">
            {Object.keys(races).map(r => (
              <div
                key={r}
                className={'selectable dw-card position-relative mx-2 mb-4 p-2' + (r === race ? ' active ' : '')}
                onClick={() => {
                  setRace(r)
                  if (!races[r].gameClasses.includes(gameClass)) {
                    setGameClass(races[r].gameClasses[0])
                  }
                }}
              >
                <Image style={styles.selectable} src={raceAssets[r]} alt={races[r].title} className="mr-3 mb-2" thumbnail/>
                <div><strong className="mb-1">{races[r].title}</strong></div>
                <div>
                  {Object.keys(races[r].stats).map((stat, i) => (
                    <small key={stat}>{i > 0 && <br/>}{`${stat} +${statToText(races[r].stats[stat])}${getUnits(stat)}`}</small>
                  ))}
                </div>
              </div>
            ))}
          </Col>
        </Row>

        <Row className="text-center">
          <Col>
            <h4>Class</h4>
          </Col>
        </Row>
        <Row>
          <Col className="text-center">
            {races[race].gameClasses.filter((gc) => gameClasses[gc].alignments.includes(alignment)).map(g => (
              <div
                key={g}
                className={(gameClasses[g].active ? 'selectable ' : 'under-construction ') + 'dw-card position-relative mx-2 mb-4 p-2' + (g === gameClass ? ' active ' : '')}
                onClick={() => {
                  if (gameClasses[g].active) {
                    setGameClass(g)
                  }
                }}
              >
                <Image style={styles.selectable} src={classAssets[g]} alt={g} className="mr-3 mb-2" thumbnail/>
                <div><strong className="mb-1">{g}</strong></div>
                <div>
                  {g === 'rogue' && <small>dual wield accuracy<br /></small>}
                  {Object.keys(gameClasses[g].stats).map((stat, i) => (
                    <small key={stat}>{i > 0 && <br/>}{`${stat} +${statToText(gameClasses[g].stats[stat])}${getUnits(stat)}`}</small>
                  ))}
                  {g !== 'rogue' && g !== 'hunter' && <small><br/>&nbsp;</small>}
                </div>
                {!gameClasses[g].active && <Image style={styles.underConstruction} src={underConstruction} alt=""/>}
              </div>
            ))}
          </Col>
        </Row>

        <Row className="justify-content-center mb-4">
          <Col md={4}>
            <Button className="w-100 mb-4" variant="success" type="submit">Create</Button>
          </Col>
        </Row>
      </Form>
    </>
  )
}

const alignments = ['Good', 'Evil', 'Neutral']

// if change something here don't forget to change same constant on server as well
const races = {
  human: {
    title: 'Human',
    gameClasses: ['warrior', 'rogue', 'druid', 'priest', 'mage', 'hunter', 'paladin', 'death knight'],
    stats: {
      'spell damage bonus': 500,
      'skill bonus': 500,
    }
  },
  dwarf: {
    title: 'Dwarf',
    gameClasses: ['rogue', 'priest', 'necromancer', 'hunter'],
    stats: {
      'magic resistance': 500,
      'damage reduction': 300,
    }
  },
  elf: {
    title: 'Elf',
    gameClasses: ['priest', 'mage', 'hunter', 'wizard'],
    stats: {
      'max mana': 2000,
      perception: 100,
    }
  },
  orc: {
    title: 'Orc',
    gameClasses: ['warrior', 'necromancer', 'death knight', 'wizard'],
    stats: {
      'damage bonus': 500,
      'pet damage bonus': 500,
    }
  },
  troll: {
    title: 'Troll',
    gameClasses: ['warrior', 'mage', 'necromancer'],
    stats: {
      'max hp': 3000,
      'hp per kill': 300,
    }
  },
  hobbit: {
    title: 'Hobbit',
    gameClasses: ['rogue', 'hunter', 'wizard'],
    stats: {
      dodge: 500,
      perception: 200,
    }
  },
}

// if change something here don't forget to change same constant on server as well
const gameClasses = {
  warrior: {
    title: 'Warrior',
    stats: {
      damage: [200, 400],
    },
    alignments: [0, 1],
    active: true,
  },
  'death knight': {
    title: 'Death Knight',
    stats: {
      'block chance': 500,
    },
    alignments: [1],
    active: true,
  },
  druid: {
    title: 'Druid',
    stats: {
      'max mana': 2500,
    },
    alignments: [0, 1],
    active: true,
  },
  hunter: {
    title: 'Hunter',
    stats: {
      critical: 500,
      perception: 100,
    },
    alignments: [0, 1],
    active: false,
  },
  mage: {
    title: 'Mage',
    stats: {
      'spell critical': 500,
    },
    alignments: [0, 1],
    active: true,
  },
  necromancer: {
    title: 'Necromancer',
    stats: {
      'max mana': 2500,
    },
    alignments: [1],
    active: true,
  },
  paladin: {
    title: 'Paladin',
    stats: {
      'block chance': 500,
    },
    alignments: [0],
    active: false,
  },
  priest: {
    title: 'Priest',
    stats: {
      'max mana': 2500,
    },
    alignments: [0],
    active: false,
  },
  rogue: {
    title: 'Rogues',
    stats: {
      perception: 200,
    },
    alignments: [0, 1],
    active: true,
  },
  wizard: {
    title: 'Wizard',
    stats: {
      'magic resistance': 500,
    },
    alignments: [0, 1],
    active: true,
  },
}

const styles = {
  selectable: {
    width: 300,
  },
  underConstruction: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    opacity: 0.5,
  }
}

export default CreateNewCharacter
